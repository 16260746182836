export const environment = {
    contentAPIURL: 'https://rocky.360medics.com/v1',
    firebaseConfig: {
        apiKey: 'AIzaSyDJdxrto-9EHkNliG4AYwI6olJeGc_cd54',
        authDomain: 'prod-medics360v2.firebaseapp.com',
        databaseURL: 'https://prod-medics360v2.firebaseio.com',
        projectId: 'prod-medics360v2',
        storageBucket: 'prod-medics360v2.appspot.com',
    },
    sfServer: 'https://360medics.com',
    production: true
}